
import { defineComponent } from "vue";
import PosTicketCard from "./PosTicketCard.vue";

export default defineComponent({
  name: "WaitingTicket",
  props: ["tickets", "modelValue"],
  emits: ["update:modelValue"],
  components: { PosTicketCard },
  setup() {
    return {};
  },
});
