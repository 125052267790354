
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  PropType,
  ref,
} from "vue";
import { getTicketType, totalInt } from "@/graphql/utils/utils";
import { activeActivity } from "@/plugins/i18n";
import { Ticket } from "@/graphql/types";
import { useI18n } from "vue-i18n";
import { ticketStatus } from "@/data/ticketStatus";

export default defineComponent({
  name: "PosTicketCard",
  props: {
    item: Object as PropType<Ticket>,
  },
  components: {
    TicketGet: defineAsyncComponent(
      () => import("@/components/ticket/TicketGet.vue")
    ),
  },
  setup(props) {
    const { t } = useI18n();
    const visible = ref(false);
    const resume = computed(() => {
      const { number, movements, customer } = props.item as Ticket;
      return {
        number: number ? t("ticket.number", { number: number }) : "",
        total: totalInt(movements),
        ...ticketStatus[getTicketType(props.item as Ticket)],
        customer: customer?.name || t("payment.clientUndefined"),
      };
    });

    const showDetails = (event: MouseEvent) => {
      event.stopPropagation();
      visible.value = true;
    };
    return {
      currencySymbol: activeActivity.value.currencySymbol,
      resume,
      showDetails,
      visible,
    };
  },
});
