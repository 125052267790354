import { TicketStatusEnum } from "@/graphql/ticket/ticket";

export type TTagSeverity =
  | "success"
  | "info"
  | "warning"
  | "danger"
  | undefined;

export const ticketStatus: Record<
  TicketStatusEnum,
  { icon: string; color: TTagSeverity }
> = {
  [TicketStatusEnum.PAYED]: {
    icon: "check",
    color: "success",
  },
  [TicketStatusEnum.CREDIT]: {
    icon: "credit-card",
    color: "success",
  },
  [TicketStatusEnum.WAITING]: {
    icon: "pause",
    color: "success",
  },
  [TicketStatusEnum.CREDIT_PAYED]: {
    icon: "check",
    color: "success",
  },
  [TicketStatusEnum.CANCELLED]: {
    icon: "check",
    color: "warning",
  },
  [TicketStatusEnum.RETURN]: {
    icon: "backward",
    color: "danger",
  },
  [TicketStatusEnum.OFFER]: {
    icon: "check-circle",
    color: "info",
  },
};
